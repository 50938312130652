import * as React from 'react';
import LocationLabels from '../components/LocationLabels';
import Spinner from '../components/Spinner';
import { printingTestLabels } from '../test/testlocations';

export function TestError() {
  throw new Error('error getriggered door /err');
  // eslint-disable-next-line no-unreachable
  return null;
}
export function TestSpinners() {
  return (
    <div>
      <Spinner />
      <Spinner />
      <Spinner />
      <Spinner />
      <Spinner />
    </div>
  );
}
export function TestLocationLabels() {
  return <LocationLabels locations={printingTestLabels} />;
}
