import classNames from 'classnames';
import * as React from 'react';
import { Link } from 'wouter';
import { useLocationProperty } from 'wouter/use-location';
import documentation from '../../documentation';
import { useDevProjectContext } from '../../hooks/useDevProjectContext';
import { useIsInternalUser } from '../../hooks/useIsInternalUser';
import CerthonMarkdown from '../Markdown';
import NotFound from '../NotFound';
import Page from '../Page';
import styles from './DocsPage.module.scss';
import ScrollIntoView from './ScrollIntoView';
import TableOfContents from './TableOfContents';

const headingComponents: any = {};
for (let i = 1; i < 10; i++) {
  headingComponents['h' + i] = (props: any) => {
    const children = React.Children.toArray(props.children);
    const text = children.reduce(flatten, '');
    const slug = text
      .toLowerCase()
      .trim()
      .replace(/[\.,]/g, '')
      .replace(/\W/g, '-');

    return React.createElement('h' + i, { id: slug }, [
      ...props.children,
      ' ',
      <Link to={`#${slug}`}>
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a className={styles.link}>
          <i className="fa fa-paperclip" />
        </a>
      </Link>,
    ]);
  };
}

const flatten = (text: string, child: any): string => {
  return typeof child === 'string'
    ? text + child
    : React.Children.toArray(child.props.children).reduce(flatten, text);
};

const DocumentationPage: React.FC<{ params: { doc?: string } }> = ({
  params,
}) => {
  let activeDoc = documentation[0]?.path;
  const hash = useLocationProperty(() => window.location.hash);
  const [users, allTickets, localTickets] = useDevProjectContext();
  const isInternalUser = useIsInternalUser();

  if (params.doc) {
    activeDoc = `/${params.doc}`;
  }
  const doc = documentation.find(({ path }) => path === activeDoc);
  if (!doc) {
    return <NotFound />;
  }

  return (
    <Page title="Documentation" className={styles.root}>
      <ScrollIntoView id={hash}>
        <div className="row">
          <div className={classNames(styles.toc, 'col-md-4')}>
            <TableOfContents
              internal={isInternalUser}
              documentation={documentation}
              activeDoc={activeDoc}
            />
          </div>
          <div className="col-md-8">
            <CerthonMarkdown
              users={users}
              allTickets={allTickets}
              localTickets={localTickets}
              className={styles.markdown as any}
              additionalComponents={headingComponents}
            >
              {doc.contents}
            </CerthonMarkdown>
          </div>
        </div>
      </ScrollIntoView>
    </Page>
  );
};

export default DocumentationPage;
