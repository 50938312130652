import * as React from 'react';
import { Dispatch } from 'redux';
import { UsersState } from '../../modules/users';
import FeedbackList from '../FeedbackList';
import Page from '../Page';
import Feedback from '../../models/Feedback';

export interface MyFeedbackPageProps {
  feedback: Feedback[] | null;
  users: UsersState;
  lists: any;
  dispatch: Dispatch;
}

const MyFeedbackPage: React.FC<MyFeedbackPageProps> = ({
  feedback,
  users,
  lists,
  dispatch
}) => {
  return (
    <Page title="feedback">
      <h2>My Feedback</h2>
      <FeedbackList
        dispatch={dispatch}
        feedback={feedback}
        users={users}
        lists={lists}
      />
    </Page>
  );
};

export default MyFeedbackPage;
