import { Artikel } from '@certhon/domain-models/lib';
import React from 'react';
import { ControlLabel, FormControl, FormGroup } from 'react-bootstrap';
import { formatCode } from '../../common/location/formatCode';
import { LocationWithDetails, Stock } from '../../modules/locations';
import { matchArtikelToTerm, renderArtikel } from '../ArtikelSearchModal';
import { MakeTree } from '../Tree/common';

interface InventorySearchPanelProps {
  locations: MakeTree<LocationWithDetails>[];
}

type Result = {
  artikel: Stock | null;
  location: LocationWithDetails;
  description: string | null;
};

const InventorySearchPanel: React.FC<InventorySearchPanelProps> = ({
  locations,
}) => {
  const [query, setQuery] = React.useState<string>('');
  const results: Result[] =
    (query && query === '=empty'
      ? locations
          .filter(
            l =>
              (l as MakeTree<LocationWithDetails>).stock.length === 0 &&
              (l as MakeTree<LocationWithDetails>).children.length === 0,
          )
          .map(location => ({ location, artikel: null, description: null }))
      : locations
          .map(l => {
            const omsArt: Artikel = {
              description: l.description,
              subset: '',
              volgnummer: 0,
              recnum: 0,
              subset_description: '',
              unit: '',
            };
            const results: Result[] = (l as LocationWithDetails).stock
              ?.filter(s => matchArtikelToTerm(s, query))
              .map(s => ({ location: l, artikel: s, description: null }));
            if (matchArtikelToTerm(omsArt, query)) {
              results.push({
                location: l,
                artikel: null,
                description: l.description,
              });
            }
            return results;
          })
          .flat()) || [];

  return (
    <>
      <FormGroup>
        <ControlLabel>Pad</ControlLabel>
        <FormControl
          name="query"
          defaultValue={query}
          onChange={event => setQuery((event.target as any).value)}
        />
        <br />
        <table className="table">
          <thead>
            <tr>
              <th>
                Artikel / <em>Omschrijving</em>
              </th>
              <th>Locatie</th>
            </tr>
          </thead>
          <tbody>
            {results.map(({ artikel, location, description }) => (
              <tr>
                <td>
                  {artikel?.description ? (
                    renderArtikel(artikel)
                  ) : description ? (
                    <em>{description}</em>
                  ) : (
                    <small>empty</small>
                  )}
                </td>
                <td>
                  <a href={`/inventory/${(location as any).id}`}>
                    {formatCode(location as any).join('')}
                  </a>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {/* {renderPath(location).join(' / ')} */}
      </FormGroup>
    </>
  );
};

export default InventorySearchPanel;
