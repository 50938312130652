import * as React from 'react';

interface Props {
  onUnfoldAll: () => void;
  onFoldAll: () => void;
}

const FoldComponent: React.FC<Props> = ({
  onFoldAll,
  onUnfoldAll,
}) => {
  return (
    <div className="controls">
      <button
        className="collapse-all btn btn-link"
        title="Collapse all"
        onClick={onFoldAll}
      >
        <span>Collapse all</span>
      </button>
      <button
        className="expand-all btn btn-link"
        title="Expand all"
        onClick={onUnfoldAll}
      >
        <span>Expand all</span>
      </button>
    </div>
  );
};

export default FoldComponent;
