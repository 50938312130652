import * as React from 'react';
import { User } from '../../models/User';
import UserToken from '../UserToken';

import styles from './UserSelection.module.scss';

const UserSelection: React.FC<{ name: string; user: User }> = ({
  name = 'gebruiker',
  user,
}) => {
  let content;
  if (user) {
    content = (
      <div className={styles.root}>
        <UserToken small user={user} withText />{' '}
      </div>
    );
  } else {
    content = (
      <div className={styles.root}>
        <small>None</small>
      </div>
    );
  }
  return (
    <div className="form-group">
      <label className="control-label">{name}</label>
      {content}
    </div>
  );
};

export default UserSelection;
