import * as Sentry from '@sentry/react';
import classNames from 'classnames';
import { curry, equals, reject, toPairs } from 'ramda';
import * as React from 'react';
import { Props as AutocompleteProps } from 'react-autocomplete';
import { DropdownButton, MenuItem } from 'react-bootstrap';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import {
  Parameter,
  ParameterOptions,
  ParametersOptions,
  parseParameters,
  Preset,
  serializeParameters,
} from './model';
import { msg } from './msg';
import ParameterAutocomplete from './ParameterAutocomplete';
import ParameterComponent from './ParameterComponent';
import styles from './ParameterizedSearch.module.scss';
import ParameterValueAutocomplete from './ParameterValueAutocomplete';

// XXX remove this. should not be nessecary
const prepareParametersOptions = (optionsDict: {
  [paramName: string]: ParameterOptions;
}): ParameterOptions[] =>
  toPairs(optionsDict).map(([name, { name: _, ...obj }]) => ({ name, ...obj }));

export const renderParameters = curry(
  (options: ParametersOptions, { name, value }) => (
    <ParameterComponent
      name={name}
      value={value}
      options={options}
      key={name}
    />
  ),
);

function verifyValue() {}

export const renderItem: AutocompleteProps['renderItem'] = (
  item: ParameterOptions | string,
  isHighlighted,
) => {
  const name = typeof item === 'string' ? item : item.name;
  const icon = typeof item === 'string' ? item : item.icon;
  return (
    <li key={name}>
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <a
        className={classNames(
          styles.parameterItem,
          isHighlighted && styles.highlighted,
        )}
      >
        <i className={`fa fa-${icon}`} /> <span>{name}</span>
      </a>
    </li>
  );
};

const shouldValueItemRender: AutocompleteProps['shouldItemRender'] = (
  item,
  val,
) => item.includes(val);
const shouldNameItemRender: AutocompleteProps['shouldItemRender'] = (
  item,
  val,
) => item.name.includes(val);

export const renderMenu: AutocompleteProps['renderMenu'] = (
  children,
  value,
  style,
) => (
  <div
    className={classNames(
      'dropdown-menu',
      children.length === 0 && styles.hidden,
    )}
  >
    {children}
  </div>
);

export interface ParameterizedSearchComponentProps
  extends Pick<React.HTMLAttributes<HTMLDivElement>, 'style' | 'className'>,
    WrappedComponentProps {
  value: string;
  onChange?: (value: string, fromPreset: boolean) => void;
  onClear?: () => void;
  onReset?: () => void;
  options: ParametersOptions;
  presets?: Preset[];
}
export interface ParameterizedSearchComponentState {
  focus: boolean;
}

class ParameterizedSearchComponent extends React.Component<
  ParameterizedSearchComponentProps,
  { error: boolean }
> {
  constructor(props: ParameterizedSearchComponentProps) {
    super(props);
    this.state = { error: false };
  }
  componentDidCatch(error: Error, info: { componentStack: string }) {
    // --> log the error
    (error as any).info = info;
    // tslint:disable-next-line:no-console
    console.log(error);
    // tslint:disable-next-line:no-console
    console.log(info.componentStack);
    Sentry.captureException(error);

    // --> reset the filter in hope of recovery
    this.props.onChange && this.props.onChange('', false);
  }

  render() {
    if (this.state.error) {
      return null;
    }
    return <InnerParameterizedSearchComponent {...this.props} />;
  }
}

class InnerParameterizedSearchComponent extends React.Component<
  ParameterizedSearchComponentProps,
  ParameterizedSearchComponentState
> {
  state = {
    focus: false,
  };

  handleFocus = () => {
    this.setState({ focus: true });
  };
  handleBlur = () => {
    this.setState({ focus: false });
  };

  handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // parse params, alter last section and return result
    const {
      target: { value },
    } = event;

    const paramSet = parseParameters(this.props.value);
    const { parameters, newParam } = paramSet;
    let { rest } = paramSet;
    if (newParam) {
      newParam.value = value;
    } else {
      rest = value;
      if (
        value.charAt(value.length - 1) === ':' &&
        !this.props.options.parameters[value.slice(0, value.length - 1)]
      ) {
        return;
      }
    }

    const res = serializeParameters({ parameters, newParam, rest });
    this.props.onChange && this.props.onChange(res, false);
  };

  handleExtraBackspace = () => {
    const paramSet = parseParameters(this.props.value);
    const { parameters, rest } = paramSet;
    let { newParam } = paramSet;

    if (newParam && newParam.value === '') {
      newParam = null;
    } else if (parameters.length) {
      parameters.splice(parameters.length - 1);
    } else {
      return;
    }
    const res = serializeParameters({ parameters, newParam, rest });
    this.props.onChange && this.props.onChange(res, false);
  };

  handleSelectParameter = (name: string) => {
    const { parameters } = parseParameters(this.props.value);
    this.props.onChange &&
      this.props.onChange(
        serializeParameters({
          parameters,
          newParam: { name, value: '' },
          rest: '',
        }),
        false,
      );
  };

  handleSelectParameterValue = (value: string) => {
    const { parameters, newParam } = parseParameters(this.props.value);
    if (newParam === null) {
      throw new Error('Unexpected newParam value');
    }
    const { name } = newParam;
    this.props.onChange &&
      this.props.onChange(
        serializeParameters({
          parameters: [...parameters, { name, value }],
          newParam: null,
          rest: '',
        }),
        false,
      );
  };

  removeParameter = (
    param: Parameter,
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault();
    let { parameters, newParam, rest } = parseParameters(this.props.value);
    parameters = reject(equals(param), parameters);
    this.props.onChange &&
      this.props.onChange(
        serializeParameters({ parameters, newParam, rest }),
        false,
      );
  };

  handleSelectPreset: React.MouseEventHandler<HTMLButtonElement> = event => {
    const presetIdx = event.currentTarget.dataset.presetIdx!;
    this.props.onChange?.(this.props.presets![parseInt(presetIdx)].value, true);
  };

  getParameters() {
    return prepareParametersOptions(this.props.options.parameters || {});
  }

  render() {
    const {
      value,
      options,
      className,
      intl,
      onClear,
      onReset,
      presets,
    } = this.props;
    const { focus } = this.state;
    const { parameters, newParam, rest } = parseParameters(value);
    let autocomplete;
    if (newParam) {
      autocomplete = (
        <ParameterValueAutocomplete
          value={newParam.value}
          parameter={newParam}
          onChange={this.handleChange}
          options={options.parameters[newParam.name]}
          onExtraBackspace={this.handleExtraBackspace}
          onSelect={this.handleSelectParameterValue}
          focusOnMount={focus}
          onFocus={this.handleFocus}
          onBlur={this.handleBlur}
          getItemValue={options.parameters[newParam.name].getValue || (x => x)}
          shouldItemRender={
            options.parameters[newParam.name].search || shouldValueItemRender
          }
          // open={true}
        />
      );
    } else {
      const paramOptions = this.getParameters();
      autocomplete = (
        <ParameterAutocomplete
          onChange={this.handleChange}
          items={paramOptions}
          value={rest}
          onExtraBackspace={this.handleExtraBackspace}
          onSelect={this.handleSelectParameter}
          placeholder={
            parameters.length ? '' : intl.formatMessage(msg.placeholder)
          }
          // options={paramOptions}
          focusOnMount={focus}
          onFocus={this.handleFocus}
          onBlur={this.handleBlur}
          shouldItemRender={shouldNameItemRender}
        />
      );
    }

    return (
      <div>
        <div
          className={classNames(
            'form-control',
            styles.root,
            className,
            options.noInput && styles.noInput,
          )}
        >
          {parameters.map(p => (
            <ParameterComponent
              key={`${p.name}-${p.value}`}
              {...p}
              options={options}
              onRemove={
                this.props.onChange &&
                (this.removeParameter.bind(this, p) as any) /* :( */
              }
            />
          ))}
          {!options.noInput && autocomplete}
          {onClear && (
            <button
              title="clear filter"
              className={styles.btn}
              onClick={onClear}
            >
              <i className="fa fa-times"></i>
            </button>
          )}
          {onReset && (
            <button
              title="reset filter"
              className={styles.btn}
              onClick={onReset}
            >
              <i className="fa fa-undo"></i>
            </button>
          )}
          {presets && presets.length > 0 && (
            <DropdownButton
              title={<i className="fa fa-bars"></i>}
              // title="Select preset"
              bsStyle="link"
              className={styles.btn}
              id="presets"
              noCaret
              pullRight
            >
              {presets.map((ps, idx) => (
                <MenuItem
                  key={idx}
                  onClick={this.handleSelectPreset}
                  data-preset-idx={idx}
                >
                  {ps.name}
                </MenuItem>
              ))}
            </DropdownButton>
          )}
        </div>
      </div>
    );
  }
}

const ParameterizedSearch = injectIntl(ParameterizedSearchComponent);
export default ParameterizedSearch;
