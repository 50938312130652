import { Location } from '@certhon/domain-models';
import classNames from 'classnames';
import { debounce } from 'lodash';
import { descend, intersperse, prop, sortWith } from 'ramda';
import React, { ReactNode } from 'react';
import {
  Checkbox,
  ControlLabel,
  FormControl,
  FormGroup,
} from 'react-bootstrap';
import { Link } from 'wouter';
import { formatCode } from '../../common/location/formatCode';
import { locationAncestors } from '../../common/location/locationAncestors';
import { replaceNumberingPlaceholder } from '../../common/location/replaceNumberingPlaceholder';
import UsersToken from '../../components/UserToken';
import { useDispatchWithErrorNotifictions } from '../../hooks/useDispatchWithErrorNotifictions';
import {
  deleteStock,
  fetchLocation,
  isLocationWithStock as isLocationWithDetails,
  labeltypes,
  LocationWithDetails,
  patchStock,
  postStock,
  saveLocation,
} from '../../modules/locations';
import { fetchUsers, UsersState } from '../../modules/users';
import { useCountingRequestStore } from '../../stores/countingRequestStore';
import { useCountingStore } from '../../stores/countingStore';
import ArtikelSearchModal from '../ArtikelSearchModal';
import FormattedRelative from '../FormattedRelative';
import Spinner from '../Spinner';
import { MakeTree, makeTreeIndex } from '../Tree/common';
import stl from './InventoryLocationDetails.module.scss';

interface InventoryLocationDetailsProps {
  location: MakeTree<LocationWithDetails>;
  isInventoryAdmin: boolean;
  users: UsersState;
}

const InventoryLocationDetails: React.FC<InventoryLocationDetailsProps> = ({
  location,
  users,
  isInventoryAdmin,
}) => {
  const dispatchWithErrorNotification = useDispatchWithErrorNotifictions();
  const [
    { countingRequests },
    countingRequestInterface,
  ] = useCountingRequestStore();
  const [countings, countingInterface] = useCountingStore();
  const [hasFetchedCountings, setHasFetchedCountings] = React.useState<boolean>(
    false,
  );
  const [
    hasFetchedCountingRequests,
    setHasFetchedCountingRequests,
  ] = React.useState<boolean>(false);

  /** list of countingrequest which include this location and are currently in effect */
  const currentCountingRequests = React.useMemo(() => {
    const now = new Date();
    return Object.values(countingRequests)
      .filter(cr => cr.start_date < now && now < cr.end_date)
      .filter(cr => {
        let loc: MakeTree<LocationWithDetails> | null = location;
        while (loc) {
          if (cr.locations.includes(loc.id)) {
            return true;
          }
          loc = loc.parent;
        }
        return false;
      });
  }, [countingRequests, location]);

  /* Fetch/refresh data */
  // fixme: should not be managing data here
  React.useEffect(() => {
    dispatchWithErrorNotification(fetchUsers());
    dispatchWithErrorNotification(fetchLocation(location.id));
    countingRequestInterface
      .fetch()
      .then(() => setHasFetchedCountingRequests(true));
    countingInterface
      .fetch({
        locations: [location.id],
        allCountings: true,
        artikelInfo: true,
      })
      .then(() => setHasFetchedCountings(true));
  }, [
    countingInterface,
    countingRequestInterface,
    dispatchWithErrorNotification,
    location.id,
  ]);

  /** List of all countings for this location */
  const allLocationCountings = React.useMemo(() => {
    if (countings[location.id]) {
      return sortWith(
        [descend(prop('created'))],
        Object.values(countings[location.id]).flat(),
      );
    }
    return [];
  }, [countings, location]);

  const [
    numChildren,
    endLocations,
    numStock,
    emptyEndLocations,
  ] = React.useMemo(() => {
    if (location.children.length === 0) {
      return [0, 0, 0, 0];
    }
    const children = Object.values(makeTreeIndex([location]));
    const endLocations = ((children as any[]) as MakeTree<
      LocationWithDetails
    >[]).reduce((num, loc) => num + (loc.children.length === 0 ? 1 : 0), 0);
    const empyEndLocations = ((children as any[]) as MakeTree<
      LocationWithDetails
    >[]).reduce(
      (num, loc) =>
        num + (loc.children.length === 0 && loc.stock.length === 0 ? 1 : 0),
      0,
    );
    const numStock = ((children as any[]) as LocationWithDetails[]).reduce(
      (num, loc) => num + loc.stock?.length || 0,
      0,
    );
    return [children.length, endLocations, numStock, empyEndLocations];
  }, [location]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleChangeFactory = () =>
    // eslint-disable-next-line react-hooks/exhaustive-deps, react-hooks/rules-of-hooks
    React.useCallback<React.FocusEventHandler<HTMLInputElement>>(
      debounce(e => {
        let { name, value }: any = e.target;
        if (e.target.type === 'checkbox') {
          value = e.target.checked;
        }
        if (location[name as keyof Location] === value) {
          return;
        }
        dispatchWithErrorNotification(
          saveLocation({ id: location.id, [name]: value }),
        );
      }, 1e3),
      [dispatchWithErrorNotification, location],
    );

  // same generic changeHandles with different debounce to avoid fast subsequent edit of next field to cancel previous
  // TODO: improve by creating a change function which accumulates changes and saves on debounce
  const [
    handleNameChange,
    handleCodeChange,
    handleReplaceChange,
    handleDescriptionChange,
    handleUnsalableChange,
  ] = Array.from({ length: 5 }, handleChangeFactory);

  const handleSelect = React.useCallback<
    React.FocusEventHandler<HTMLSelectElement>
  >(
    e => {
      const { name, value } = e.target;
      dispatchWithErrorNotification(
        saveLocation({ id: location.id, [name]: value }),
      );
    },
    [dispatchWithErrorNotification, location.id],
  );

  const [
    displayArtikelSearchModal,
    setDisplayArtikelSearchModal,
  ] = React.useState<boolean>(false);
  const handleArtikel = React.useCallback(
    (artikelRecnum: number) => {
      setDisplayArtikelSearchModal(false);
      dispatchWithErrorNotification(postStock(location.id, artikelRecnum));
    },
    [dispatchWithErrorNotification, location.id],
  );

  let details: ReactNode = <Spinner />;
  if (isLocationWithDetails(location)) {
    details = (
      <>
        <h6>Labels</h6>
        <dl className="dl-horizontal">
          <dt>Labeltype</dt>
          <dd>
            <select
              disabled={!isInventoryAdmin}
              defaultValue={location.labeltype || ''}
              name="labeltype"
              onChange={handleSelect}
            >
              <option value="">-</option>
              {labeltypes.map(type => (
                <option key={type} value={type}>
                  {type}
                </option>
              ))}
            </select>{' '}
          </dd>
          <dt>Laatst afgedrukt</dt>
          <dd>
            {location.label_created ? (
              <FormattedRelative value={location.label_created} />
            ) : (
              '-'
            )}
          </dd>
        </dl>
        {location.children.length === 0 ? (
          <>
            <h6>Voorraad</h6>
            <table
              className={classNames('table table-striped', stl.stockTable)}
            >
              <tbody>
                {location.stock.map(stock => (
                  <tr
                    key={stock.recnum}
                    className={classNames(stock.unsalable && stl.unsalable)}
                  >
                    <td>
                      <span>
                        {stock.subset}-{stock.volgnummer}
                      </span>{' '}
                      {stock.description}
                      <button
                        disabled={!isInventoryAdmin}
                        type="button"
                        className="btn btn-xs  pull-right btn-link"
                        onClick={ev =>
                          dispatchWithErrorNotification(
                            deleteStock(location.id, stock.recnum),
                          )
                        }
                      >
                        <i className="fa fa-trash"></i>
                      </button>
                      <button
                        disabled={!isInventoryAdmin}
                        type="button"
                        className="btn btn-xs  pull-right btn-link"
                        onClick={ev =>
                          dispatchWithErrorNotification(
                            patchStock(
                              location.id,
                              stock.recnum,
                              !stock.unsalable,
                            ),
                          )
                        }
                      >
                        <i className="fa fa-dollar"></i>
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
              <tfoot>
                <tr>
                  <td>
                    <button
                      disabled={!isInventoryAdmin}
                      className="btn btn-sm pull-right"
                      type="button"
                      onClick={() => setDisplayArtikelSearchModal(true)}
                    >
                      Nieuw artikel
                    </button>
                  </td>
                </tr>
              </tfoot>
            </table>
            <h6>Countings</h6>
            <table className="table table-condensed">
              <thead>
                <th>User</th>
                <th>Date</th>
                <th>Product</th>
                <th>amount</th>
              </thead>
              <tbody>
                {!hasFetchedCountings ? (
                  <tr>
                    <td colSpan={3}>
                      <Spinner></Spinner>
                    </td>
                  </tr>
                ) : (
                  allLocationCountings.map((c, idx) => {
                    const user = users?.[c.creator_user_id];
                    const stock =
                      location.stock.find(s => s.recnum === c.artikel_id) ||
                      c.artikel ||
                      null;

                    return (
                      <tr key={idx}>
                        <td>
                          {user && <UsersToken user={user} small withText />}
                        </td>
                        <td>
                          <FormattedRelative value={c.created} />
                        </td>
                        <td>
                          {stock &&
                            `${stock.subset}-${stock.volgnummer} ${stock.description}`}
                        </td>
                        <td title={c.amount_input || ''}>{c.amount}</td>
                      </tr>
                    );
                  })
                )}
              </tbody>
            </table>
          </>
        ) : (
          <>
            <h6>Statistieken</h6>
            <dl className="dl-horizontal">
              <dt>Sublocaties</dt>
              <dd>{numChildren}</dd>
              <dt>Voorraadartikelen</dt>
              <dd>{numStock}</dd>
              <dt>Eindlocaties</dt>
              <dd>{endLocations}</dd>
              <dt>Lege eindlocaties</dt>
              <dd>
                {emptyEndLocations} (
                {Math.round((100 * emptyEndLocations) / endLocations)}%)
              </dd>
            </dl>
            <h6>Current Counting Requests</h6>
            <table className="table table-condensed">
              <thead>
                <th colSpan={2}>Name</th>
              </thead>
              <tbody>
                {!hasFetchedCountingRequests ? (
                  <tr>
                    <td colSpan={3}>
                      <Spinner></Spinner>
                    </td>
                  </tr>
                ) : (
                  currentCountingRequests.map((cr, idx) => (
                    <tr key={idx}>
                      <td>{cr.name}</td>
                      <td>
                        <Link
                          to={`/inventory/${location.id}/counting-request/${cr.recnum}`}
                        >
                          tellen <i className="fa fa-chevron-right"></i>
                        </Link>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </>
        )}
        <></>
      </>
    );
  }

  return (
    <form
      className={stl.root}
      key={
        location.id // rerender when other location is selected to repopulate defaultValues
      }
    >
      <h3>Opslaglocatie</h3>
      <div className="row">
        <div className="col-xs-7">
          <FormGroup>
            <ControlLabel>Pad</ControlLabel>
            <>
              <br />
              {intersperse(
                <span> / </span>,
                locationAncestors(location).map(l => (
                  <Link to={`/inventory/${l.id}`}>
                    {replaceNumberingPlaceholder(l.name, l.ordering)}
                  </Link>
                )),
              )}
            </>
          </FormGroup>
        </div>
        <div className="col-xs-5">
          <FormGroup>
            <ControlLabel>code</ControlLabel>
            <br />
            {formatCode(location).join('')}
          </FormGroup>
        </div>
      </div>
      <h6>Eigenschappen</h6>
      <div className="row">
        <div className="col-xs-8">
          <FormGroup>
            <ControlLabel>name</ControlLabel>
            <FormControl
              defaultValue={location.name}
              onBlur={handleNameChange as any}
              onChange={handleNameChange as any}
              name="name"
              disabled={!isInventoryAdmin}
            />
          </FormGroup>
        </div>
        <div className="col-xs-4">
          <FormGroup>
            <ControlLabel>code</ControlLabel>
            <FormControl
              name="code"
              defaultValue={location.code}
              onBlur={handleCodeChange as any}
              onChange={handleCodeChange as any}
              disabled={!isInventoryAdmin}
            />
          </FormGroup>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-8">
          <FormGroup>
            <ControlLabel>description</ControlLabel>
            <FormControl
              defaultValue={location.description}
              onBlur={handleDescriptionChange as any}
              onChange={handleDescriptionChange as any}
              name="description"
              disabled={!isInventoryAdmin}
            />
          </FormGroup>
        </div>
        <div className="col-xs-4">
          <FormGroup>
            <ControlLabel title="Description replaces name-path">
              replace path{' '}
              <Link style={{ display: 'inline-block' }} to="/docs/9-inventory">
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a>
                  <i className="fa fa-info-circle"></i>
                </a>
              </Link>
            </ControlLabel>
            <Checkbox
              name="name_replaces_path"
              defaultChecked={location.name_replaces_path}
              onBlur={handleReplaceChange as any}
              onChange={handleReplaceChange as any}
              disabled={!isInventoryAdmin}
            ></Checkbox>
          </FormGroup>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12">
          <Checkbox
            name="unsalable"
            defaultChecked={location.unsalable}
            onBlur={handleUnsalableChange as any}
            onChange={handleUnsalableChange as any}
            disabled={!isInventoryAdmin}
            title="Deze locatie wordt gebruikt om incourant materiaal op te bergen"
          >
            Incourant
          </Checkbox>
        </div>
      </div>
      {details}
      <ArtikelSearchModal
        show={displayArtikelSearchModal}
        onCancel={() => setDisplayArtikelSearchModal(false)}
        onChooseArtikel={handleArtikel}
      />
    </form>
  );
};

export default InventoryLocationDetails;
