import { groupBy, map, pipe, prop, sortBy, toPairs, values } from 'ramda';

interface DenormalizedClientInfo {
  klant: string;
  klant_naam: string;
  klant_id: number;
}

type GroupedByClient<T extends DenormalizedClientInfo> = Array<{
  client: string;
  clientId: string;
  items: T[];
}>;

const groupByClient = <T extends DenormalizedClientInfo>(
  items: T[],
): GroupedByClient<T> =>
  pipe<[T[]], T[], any, any, any, GroupedByClient<T>>(
    values as any, // :/
    groupBy(w => w.klant_id.toString()),
    toPairs,
    map(([, items]) => ({
      client: items[0].klant_naam || items[0].klant,
      clientId: items[0].klant_id,
      items,
    })),
    sortBy(prop('client')),
  )(items);

export default groupByClient;
