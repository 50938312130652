import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { MenuItem, Nav, Navbar, NavDropdown, NavItem } from 'react-bootstrap';
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl';
import { push } from 'redux-first-history';
import { Link } from 'wouter';
import logoImg from '../../assets/images/logo_white.png';
import {
  doesListHaveUnsavedModifications,
  doesListHaveUnsyncedModifications,
} from '../../common/logic/list';
import { doesTicketHaveUnsyncedEvents } from '../../common/logic/ticket';
import { signOut } from '../../modules/user';
import InventoryCounterOnly from '../../utils/CounterOnly';
import InternalOnly from '../../utils/InternalOnly';
import LinkContainer from '../LinkContainer';
import UserToken from '../UserToken';
import styles from './Page.module.scss';

const msg = defineMessages({
  signOutWarnContent: {
    id: 'SIGN_OUT.CONTENT_WARNING',
    defaultMessage:
      'Not all local content has been synced. This CONTENT WILL BE LOST if you sign out!\n\n Do you really want to sign out?',
  },
  signOutWarnOffline: {
    id: 'SIGN_OUT.OFFLINE_WARNING',
    defaultMessage:
      "The backend is not reachabe. If you sign out now, you won't be able to sign back in until the backend is reachable\n\n Do you really want to sign out?",
  },
});

export default injectIntl(
  class Navigation extends Component {
    static propTypes = {
      user: PropTypes.object,
      dispatch: PropTypes.func.isRequired,
      fluid: PropTypes.bool,
      lists: PropTypes.object,
    };

    handleSignOut = () => {
      const { lists, tickets, feedback, online, intl } = this.props;
      const hasUnsynced =
        (lists &&
          Object.values(lists).some(doesListHaveUnsyncedModifications)) ||
        (tickets &&
          Object.values(tickets).some(doesTicketHaveUnsyncedEvents)) ||
        (feedback && Object.values(feedback).some(f => f._sync));

      // eslint-disable-next-line no-restricted-globals
      if (!hasUnsynced || confirm(intl.formatMessage(msg.signOutWarnContent))) {
        // eslint-disable-next-line no-restricted-globals
        if (online || confirm(intl.formatMessage(msg.signOutWarnOffline))) {
          this.props.dispatch(signOut());
          this.props.dispatch(push('/login'));
        }
      }
    };

    getLeftItems() {
      const { user, lists } = this.props;
      if (user) {
        let templatesClass = '';
        if (
          lists &&
          Object.values(lists).some(doesListHaveUnsavedModifications)
        ) {
          templatesClass = styles.unsaved;
        }
        const adminItems = [];
        if (user && user.permissions.QA_ADMIN) {
          adminItems.push(
            <LinkContainer to="/templates" key="templates">
              <MenuItem className={'hidden-xs ' + templatesClass} eventKey={3}>
                <FormattedMessage
                  id="NAVIGATION.TEMPLATES"
                  defaultMessage="Templates"
                  description=""
                />
              </MenuItem>
            </LinkContainer>,
          );
          adminItems.push(
            <LinkContainer to="/labels" key="labels">
              <MenuItem className="hidden-xs" eventKey={3}>
                <FormattedMessage
                  id="NAVIGATION.LABELS"
                  defaultMessage="Labels"
                  description=""
                />
              </MenuItem>
            </LinkContainer>,
          );
          adminItems.push(
            <LinkContainer to="/stats" key="stats">
              <MenuItem className="hidden-xs" eventKey={3}>
                <FormattedMessage
                  id="NAVIGATION.STATS"
                  defaultMessage="Stats"
                  description=""
                />
              </MenuItem>
            </LinkContainer>,
          );
        }
        if (user && user.permissions.USER_ADMIN) {
          adminItems.push(
            <LinkContainer to="/accounts" key="accounts">
              <MenuItem className="hidden-xs" eventKey={3}>
                <FormattedMessage
                  id="NAVIGATION.ACCOUNTS"
                  defaultMessage="Accounts"
                  description=""
                />
              </MenuItem>
            </LinkContainer>,
          );
        }
        if (user && user.permissions.QA_SUPER_USER) {
          adminItems.push(
            <LinkContainer
              to="/cache-administration"
              key="cache-administration"
            >
              <MenuItem eventKey={3}>
                <FormattedMessage
                  id="NAVIGATION.CACHE_ADMINISTRATION"
                  defaultMessage="Cache"
                  description=""
                />
              </MenuItem>
            </LinkContainer>,
          );
        }
        const items = (
          <>
            <LinkContainer to="/projects" key="projects">
              <NavItem eventKey={1}>
                <FormattedMessage
                  id="NAVIGATION.PROJECTS"
                  defaultMessage="Projects"
                />
              </NavItem>
            </LinkContainer>
            <InternalOnly user={user}>
              <LinkContainer to="/dashboard" key="dashboard">
                <NavItem eventKey={2}>
                  <FormattedMessage
                    id="NAVIGATION.DASHBOARD"
                    defaultMessage="Dashboard"
                  />
                </NavItem>
              </LinkContainer>
            </InternalOnly>
            <InventoryCounterOnly user={user}>
              <NavDropdown id="inventory" title="Inventory" eventKey={3}>
                <LinkContainer to="/inventory" key="inventory">
                  <NavItem eventKey={3.1}>Locations</NavItem>
                </LinkContainer>
                <LinkContainer to="/inventory/search" key="search">
                  <NavItem eventKey={3.3}>Search</NavItem>
                </LinkContainer>
                <LinkContainer to="/counting-requests" key="countingrequests">
                  <NavItem eventKey={3.2}>Counting Requests</NavItem>
                </LinkContainer>
                {/* <LinkContainer to="/counting-requests" key="inventory"> */}
                <NavItem disabled eventKey={3.3}>
                  Low-stock alerts
                </NavItem>
                {/* </LinkContainer> */}
              </NavDropdown>
            </InventoryCounterOnly>
            {adminItems.length > 1 && (
              <NavDropdown
                eventKey={4}
                title="Admin"
                id="admin"
                className="hidden-xs"
              >
                {adminItems}
              </NavDropdown>
            )}
            {adminItems.length === 1 && adminItems[0]}
          </>
        );
        return items;
      }
      return null;
    }

    getRightItems() {
      const { user } = this.props;
      if (!user) {
        return null;
      }
      return (
        <Nav pullRight>
          <NavDropdown
            eventKey="4"
            title={
              <span>
                <UserToken small className={styles.userToken} user={user} />{' '}
                {user.loginname}
              </span>
            }
            id="nav-dropdown"
          >
            <LinkContainer to="/settings" key="settings">
              <MenuItem>
                <FormattedMessage
                  id="NAVIGATION.SETTINGS"
                  defaultMessage="Settings"
                />
              </MenuItem>
            </LinkContainer>
            <LinkContainer to="/feedback" key="feedback">
              <MenuItem>
                <FormattedMessage
                  id="NAVIGATION.FEEDBACK"
                  defaultMessage="My Feedback"
                />
              </MenuItem>
            </LinkContainer>
            <MenuItem divider />
            <MenuItem onClick={this.handleSignOut}>
              <FormattedMessage
                id="NAV.SIGN_OUT"
                defaultMessage="Sign out"
                description="Label of signout button"
              />
            </MenuItem>
          </NavDropdown>
        </Nav>
      );
    }

    render() {
      let environmentFilter = undefined;
      if (window.location.hostname !== 'certhon.app') {
        environmentFilter = {
          style: { filter: 'brightness(50%) sepia(100%) saturate(10000%)' },
          title: 'This is not the production environment!',
        };
      }

      const { fluid } = this.props;
      return (
        <Navbar inverse className={styles.nav} collapseOnSelect fluid={fluid}>
          <Navbar.Header>
            <Link to="/">
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid*/}
              <a className="navbar-brand">
                <img
                  role="presentation"
                  alt="certhon logo"
                  src={logoImg}
                  {...environmentFilter}
                />
              </a>
            </Link>
            <Navbar.Toggle />
          </Navbar.Header>
          <Navbar.Collapse>
            <Nav>{this.getLeftItems()}</Nav>
            {this.getRightItems()}
          </Navbar.Collapse>
        </Navbar>
      );
    }
  },
);
