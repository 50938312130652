import { filter as rFilter } from 'ramda';
import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import Types from 'Types';
import MultiTicketReport from '../components/MultiTicketReport';
import useReportReady from '../hooks/useReportReady';
import useSearchParams from '../hooks/useSearchParams';
import { fetchLabels, LabelsState } from '../modules/labels';
import { fetchProjects, ProjectState } from '../modules/projects';
import { fetchTickets } from '../modules/tickets';
import { fetchUser, UserState } from '../modules/user';
import { fetchUsers, UsersState } from '../modules/users';
import { fetchWorks, Work, WorkState } from '../modules/works';
import assertAndParseInt from '../utils/assertAndParseInt';
import useInitialDispatch from '../utils/useInitalDispatch';

export interface Props {
  params: { projectId: string };
  projects: ProjectState;
  tickets: any;
  works: WorkState;
  users: UsersState;
  user: UserState;
  labels: LabelsState;
  dispatch: Dispatch;
}

const isLoading = (p: Props) => {
  return (
    !p.users ||
    !p.tickets ||
    !p.works ||
    !p.user ||
    !p.projects ||
    !p.works ||
    !p.labels
  );
};
const MultiProjectTicketReportContainer: React.FC<Props> = p => {
  const { projects, works, tickets, users, user, labels, params } = p;

  const projectId = assertAndParseInt(params.projectId);
  const [{ filter, sorting }] = useSearchParams({
    filter: '',
    sorting: '',
  });

  useInitialDispatch(
    fetchWorks,
    fetchProjects,
    fetchUsers,
    fetchUser,
    fetchLabels,
    () => fetchTickets({ project_id: projectId }),
  );

  const ready = useReportReady(() => !isLoading(p), [p]);

  const projectWorks = React.useMemo<Work[]>(
    () => Object.values(works).filter(w => w.project_id === projectId),
    [projectId, works],
  );

  const workIds = projectWorks?.map(w => w.recnum);

  const projectTickets =
    ready &&
    rFilter(
      (t: any) =>
        !!(t.project_id === projectId || workIds?.includes(t.werk_id)),
      tickets,
    );

  return (
    <>
      {ready && (
        <MultiTicketReport
          works={projectWorks}
          tickets={projectTickets}
          users={users}
          user={user}
          filter={filter}
          sorting={sorting}
          projects={projects}
          projectId={projectId}
          labels={labels}
        />
      )}
    </>
  );
};

function mapStateToProps(state: Types.RootState) {
  return {
    projects: state.projects,
    works: state.works,
    users: state.users,
    user: state.user,
    tickets: state.tickets,
    labels: state.labels,
  };
}

export default connect(mapStateToProps)(MultiProjectTicketReportContainer);
