/** Check if a response is ok, throw an excepion otherwise */
export async function verifyResponse(response: Response) {
  if (!response.ok) {
    let message;
    try {
      const { error } = await response.json();
      message = error;
    } catch (e) {
      message = response.statusText;
    }
    throw new Error(message);
  }
}
