import React from 'react';
import { Label } from '@certhon/domain-models/lib';
import styles from './LabelToken.module.scss';
import classNames from 'classnames';

const { contrastColor } = require('contrast-color'); // tslint:disable-line

interface Props
  extends Pick<React.HtmlHTMLAttributes<HTMLDivElement>, 'className'> {
  label: Label;
  onRemove?: () => void;
}

const LabelToken: React.FC<Props> = ({
  label: { name, color, description },
  onRemove,
  className,
}) => {
  const style: React.CSSProperties = {};
  if (color) {
    style.backgroundColor = '#' + color;
    style.color = contrastColor({ bgColor: style.backgroundColor, threshold: 200  });
  }
  return (
    <div
      style={style}
      title={description}
      className={classNames(styles.parameter, className)}
    >
      <span>{name}</span>
      {onRemove && (
        <button type="button" className={styles.removeBtn} onClick={onRemove}>
          <i className={`fa fa-times`} />
        </button>
      )}
    </div>
  );
};

export default LabelToken;
