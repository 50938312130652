import classNames from 'classnames';
import * as React from 'react';
import { User } from '../../models/User';
import styles from './UserToken.module.scss';

const randomColor = require('randomcolor'); // tslint:disable-line
const { contrastColor } = require('contrast-color'); // tslint:disable-line

export interface UserTokenProps {
  user: User;
  className?: string;
  small?: boolean;
  collapse?: boolean;
  withText?: boolean; // fixme: invert, default true is expected
  onDeselect?: () => void;
}

const UserToken: React.FC<UserTokenProps> = ({
  user,
  className,
  small = false,
  collapse = false,
  onDeselect,
  withText = false,
}) => {
  if (!user) {
    return null;
  }
  const initials =
    user.loginname?.substring(0, 1) || user.name?.substring(0, 1) || '#';
  const color = randomColor({ seed: user.recnum });
  const textColor = contrastColor({ bgColor: color, threshold: 220  });
  const token = (
    <div
      className={classNames(!withText && className, styles.root, {
        [styles.small]: small,
        [styles.collapse]: collapse,
      })}
      style={{ backgroundColor: color, color: textColor }}
      title={user.name ? `${user.name} (${user.loginname})` : user.loginname}
      data-user-token-loginname={user.loginname}
    >
      <span>{initials.toUpperCase()}</span>
    </div>
  );
  if (withText) {
    return (
      <div
        title={user.loginname}
        className={classNames(className, styles.textRoot)}
      >
        {token}
        <span className={styles.name}>{user.name || user.loginname}</span>
        {onDeselect && (
          <button
            type="button"
            className={styles.deselectBtn}
            onClick={onDeselect}
          >
            &times;
          </button>
        )}
      </div>
    );
  }
  return token;
};

export default UserToken;
